import {
  DashboardOutlined,
  AppstoreOutlined,
  PaperClipOutlined,
  StarOutlined,
  FileOutlined,
  TeamOutlined,
  ForkOutlined,
  PullRequestOutlined,
  BellOutlined,
  CreditCardOutlined,
  IdcardOutlined
} from '@ant-design/icons';

const navigationConfig = (permissions = []) => {
  const dashBoardNavTree = [{
    key: 'dashboard',
    path: `/dashboard`,
    title: 'sidenav.dashboard',
    icon: DashboardOutlined,
    breadcrumb: false,
    hidden: !permissions.includes('view.report'),
    submenu: [
    ],
  }];
  
  const customersNavTree = [{
    key: 'customers',
    path: `/users`,
    title: 'Customers',
    icon: AppstoreOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: 'verification',
        path: `/verification`,
        title: 'Identity Verification',
        icon: IdcardOutlined,
        breadcrumb: false,
        submenu: [],
        hidden: !permissions.includes('view.verification')
      },
      {
        key: 'users',
        path: `/users`,
        title: 'Users',
        icon: TeamOutlined,
        breadcrumb: false,
        submenu: [],
        hidden: !permissions.includes('view.user')
      },
      {
        key: 'pendingdocuments',
        path: `/pendingdocuments`,
        title: 'Pending Documents',
        icon: FileOutlined,
        breadcrumb: false,
        submenu: [],
        hidden: !permissions.includes('view.document')
      },
      {
        key: 'unmatchedimages',
        path: `/unmatchedimages`,
        title: 'Un Matched Images',
        icon: FileOutlined,
        breadcrumb: false,
        submenu: [],
        hidden: !permissions.includes('view.document')
      },
      {
        key: 'statements',
        path: `/statements`,
        title: 'Statements',
        icon: FileOutlined,
        breadcrumb: false,
        submenu: [],
        hidden: !permissions.includes('view.user')
      },
      {
        key: 'p2p_loans',
        path: `/p2p_loans`,
        title: 'P2P Loans',
        icon: PaperClipOutlined,
        breadcrumb: false,
        submenu: [],
        hidden: !permissions.includes('view.p2p_loans')
      },
      {
        key: 'offers',
        path: `/offers`,
        title: 'Loans Offers',
        icon: PaperClipOutlined,
        breadcrumb: false,
        submenu: [],
        hidden: !permissions.includes('view.p2p_loans')
      },
      
      {
        key: 'loan_requests',
        path: `/loan_requests`,
        title: 'Loan Requests',
        icon: PullRequestOutlined,
        breadcrumb: false,
        submenu: [],
        hidden: !permissions.includes('view.loans')
      },
      {
        key: 'guarantors',
        path: `/guarantors`,
        title: 'Guarantors',
        icon: ForkOutlined,
        breadcrumb: false,
        submenu: [],
        hidden: !permissions.includes('view.guarantor')
      },
      {
        key: 'notifications',
        path: `/notifications`,
        title: 'Notifications',
        icon: BellOutlined,
        breadcrumb: false,
        submenu: [],
        hidden: !permissions.includes('view_notification')
      },
      {
        key: 'lenders',
        path: `/lenders`,
        title: 'Lenders',
        icon: TeamOutlined,
        breadcrumb: false,
        submenu: [],
        hidden: !permissions.includes('view.lenders')
      },
      // {
      //   key: 'whitelist',
      //   path: `/whitelist`,
      //   title: 'Whitelist',
      //   icon: UserAddOutlined,
      //   breadcrumb: false,
      //   submenu: [],
      // },
      {
        key: 'transactions',
        path: `/transactions`,
        title: 'Transactions',
        icon: CreditCardOutlined,
        breadcrumb: false,
        submenu: [],
        hidden: !permissions.includes('view.transaction')
      },
      {
        key: 'posting',
        path: `/posting`,
        title: 'Loan Posting',
        icon: CreditCardOutlined,
        breadcrumb: false,
        submenu: [],
        hidden: !permissions.includes('view.posting')
      },
      {
        key: 'errorlogs',
        path: `/error`,
        title: 'Issue Logs',
        icon: PaperClipOutlined,
        breadcrumb: false,
        submenu: [],
        hidden: !permissions.includes('view.transaction')
      },
      {
        key: 'manual',
        path: `/manual_loan`,
        title: 'Manual Loan',
        icon: PaperClipOutlined,
        breadcrumb: false,
        submenu: [],
        hidden: !permissions.includes('view.manual')
      }
    ],
  }];
  
  const settingsNavTree = [{
    key: 'settings',
    path: `/settings`,
    title: 'Settings',
    icon: AppstoreOutlined,
    breadcrumb: false,
    hidden: !permissions.includes('view.tier') && !permissions.includes('view.transaction'),
    submenu: [
      {
        key: 'tiers',
        path: `/tiers`,
        title: 'Tier Management',
        icon: StarOutlined,
        breadcrumb: false,
        submenu: [],
        hidden: !permissions.includes('view.tier'),
      },
      {
        key: 'error-logs',
        path: `/error/definitions`,
        title: 'Error Logs',
        icon: TeamOutlined,
        breadcrumb: false,
        submenu: [],
        hidden: !permissions.includes('view.role')
      },
      // {
      //   key: 'risk',
      //   path: `/risk`,
      //   title: 'Risk Settings',
      //   icon: SafetyCertificateOutlined,
      //   breadcrumb: false,
      //   submenu: [],
      // },
      // {
      //   key: 'profile',
      //   path: `/profile`,
      //   title: 'Profile',
      //   icon: UserOutlined,
      //   breadcrumb: false,
      //   submenu: [],
      // },
      {
        key: 'internal',
        path: `/internal`,
        title: 'Internal Transactions',
        icon: CreditCardOutlined,
        breadcrumb: false,
        submenu: [],
        hidden: !permissions.includes('view.transaction')
      }
    ],
  }];

  const systemNavTree = [{
    key: 'system',
    path: `/system/users`,
    title: 'System',
    icon: AppstoreOutlined,
    breadcrumb: false,
    hidden: !permissions.includes('view.team') && !permissions.includes('view.role'),
    submenu: [
      {
        key: 'system-users',
        path: `/system/users`,
        title: 'Users Management',
        icon: TeamOutlined,
        breadcrumb: false,
        submenu: [],
        hidden: !permissions.includes('view.team'),
      },
      {
        key: 'system-roles',
        path: `/system/roles`,
        title: 'Roles/Permissions',
        icon: TeamOutlined,
        breadcrumb: false,
        submenu: [],
        hidden: !permissions.includes('view.role')
      },
      
    ],
  }];

  const reportNavTree = [{
    key: 'report',
    path: `/reports`,
    title: 'Report',
    icon: AppstoreOutlined,
    breadcrumb: false,
    hidden: !permissions.includes('view.loanreports'),
    submenu: [
      {
        key: 'creditbureau',
        path: `/reports/creditbureau`,
        title: 'Credit Bureau',
        icon: TeamOutlined,
        breadcrumb: false,
        submenu: [],
        hidden: !permissions.includes('view.loanreports')
      },
      {
        key: 'collecction',
        path: `/reports/collection`,
        title: 'Collection',
        icon: TeamOutlined,
        breadcrumb: false,
        submenu: [],
        hidden: !permissions.includes('view.loanreports')
      },
      {
        key: 'commissions',
        path: `/reports/commissions`,
        title: 'Commissions',
        icon: TeamOutlined,
        breadcrumb: false,
        submenu: [],
        hidden: !permissions.includes('view.loanreports')
      },
    
      {
        key: 'investor',
        path: `/reports/investors`,
        title: 'Investor',
        icon: TeamOutlined,
        breadcrumb: false,
        submenu: [],
        hidden: !permissions.includes('view.loanreports')
      },
      {
        key: 'loans',
        path: `/reports`,
        title: 'Loans',
        icon: TeamOutlined,
        breadcrumb: false,
        submenu: [],
        hidden: !permissions.includes('view.loanreports')
      },
      {
        key: 'lenders',
        path: `/reports/profits`,
        title: 'Lenders Profits',
        icon: TeamOutlined,
        breadcrumb: false,
        submenu: [],
        hidden: !permissions.includes('view.loanreports')
      },
      {
        key: 'loanpayments',
        path: `/reports/payment`,
        title: 'Loan Payments',
        icon: TeamOutlined,
        breadcrumb: false,
        submenu: [],
        hidden: !permissions.includes('view.loanreports')
      },
      {
        key: 'Referrals',
        path: `/reports/referrals`,
        title: 'Referrals',
        icon: TeamOutlined,
        breadcrumb: false,
        submenu: [],
        hidden: !permissions.includes('view.loanreports')
      }
    ],
  }];

  const invoicingNavTree = [{
    key: 'invoicings',
    path: `/Invoicings`,
    title: 'Invoicings',
    icon: AppstoreOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: 'invoicings',
        path: `/invoicings`,
        title: 'Invoicings',
        icon: TeamOutlined,
        breadcrumb: false,
        submenu: [],
        hidden: !permissions.includes('view.user')
      },
      {
        key: 'buyers',
        path: `/invoicings/buyer`,
        title: 'Buyers',
        icon: TeamOutlined,
        breadcrumb: false,
        submenu: [],
        hidden: !permissions.includes('view.user')
      },
      {
        key: 'linecredit',
        path: `/linecredit`,
        title: 'Line Credit',
        icon: TeamOutlined,
        breadcrumb: false,
        submenu: [],
        hidden: !permissions.includes('view.user')
      },
      {
        key: 'linecreditadmin',
        path: `/linecredit/admin`,
        title: 'Line Credit Admin',
        icon: TeamOutlined,
        breadcrumb: false,
        submenu: [],
        hidden: !permissions.includes('view.user')
      }
    ],
  }];
  const mandateNavTree = [{
    key: 'mandates',
    path: `/mandate`,
    title: 'Mandates',
    icon: AppstoreOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: 'mandates',
        path: `/mandates`,
        title: 'Mandate',
        icon: PaperClipOutlined,
        breadcrumb: false,
        submenu: [],
        hidden: !permissions.includes('view.user')
      }
    ],
  }];

  const promoNavTree = [{
    key: 'promo',
    path: `/promos`,
    title: 'Promo',
    icon: AppstoreOutlined,
    breadcrumb: false,
    hidden: !permissions.includes('view.loanreports'),
    submenu: [
      {
        key: 'promo',
        path: `/promos/promo`,
        title: 'Promo',
        icon: TeamOutlined,
        breadcrumb: false,
        submenu: [],
        hidden: !permissions.includes('view.loanreports')
      },
      {
        key: 'Win Promo',
        path: `/promos/promo/win`,
        title: 'Win Users',
        icon: TeamOutlined,
        breadcrumb: false,
        submenu: [],
        hidden: !permissions.includes('view.loanreports')
      }

    ],
  }];
  
  const financialReportNavTree = [{
    key: 'financial',
    path: `/financial`,
    title: 'Financial Report',
    icon: AppstoreOutlined,
    breadcrumb: false,
    hidden: !permissions.includes('view.financialreport'),
    submenu: [
      {
        key: 'loan-balances',
        path: `/reports/loan-balances`,
        title: 'Monthly Loan Balances',
        icon: TeamOutlined,
        breadcrumb: false,
        submenu: [],
        hidden: !permissions.includes('view.financialreport')
      },
      {
        key: 'user-balances',
        path: `/reports/user-balances`,
        title: 'Users Loan Balances',
        icon: TeamOutlined,
        breadcrumb: false,
        submenu: [],
        hidden: !permissions.includes('view.financialreport')
      },
      {
        key: 'loan-payments',
        path: `/reports/loan-payments`,
        title: 'Monthly Loan Payments',
        icon: TeamOutlined,
        breadcrumb: false,
        submenu: [],
        hidden: !permissions.includes('view.financialreport')
      },
      {
        key: 'group-loan',
        path: `/groups`,
        title: 'Groups',
        icon: TeamOutlined,
        breadcrumb: false,
        submenu: [],
        hidden: !permissions.includes('view.financialreport')
      },
      {
        key: 'group-loan-request',
        path: `/groups/loan-requests`,
        title: 'Group Loan Request',
        icon: TeamOutlined,
        breadcrumb: false,
        submenu: [],
        hidden: !permissions.includes('view.financialreport')
      },
    ],
  }];

  const businessNavTree = [{
    key: 'corporate',
    path: `/Corporate`,
    title: 'Corporate',
    icon: AppstoreOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: 'business',
        path: `/business`,
        title: 'Business',
        icon: TeamOutlined,
        breadcrumb: false,
        submenu: [],
        hidden: !permissions.includes('view.user')
      },
      {
        key: 'merchants',
        path: `/merchants`,
        title: 'Merchants',
        icon: TeamOutlined,
        breadcrumb: false,
        submenu: [],
        hidden: !permissions.includes('view.user')
      },
      {
        key: 'schools',
        path: `/schools`,
        title: 'Schools',
        icon: TeamOutlined,
        breadcrumb: false,
        submenu: [],
        hidden: !permissions.includes('view.user')
      },
      {
        key: 'icsReports',
        path: `/ics`,
        title: 'ICS Reports',
        icon: TeamOutlined,
        breadcrumb: false,
        submenu: [],
        hidden: !permissions.includes('view.user')
      },
      
    ],
  }];

  const insuranceNavTree = [{
    key: 'insurance',
    path: `/Insurance`,
    title: 'Insurance',
    icon: AppstoreOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: 'insurancce',
        path: `/insurance`,
        title: 'Insurance',
        icon: TeamOutlined,
        breadcrumb: false,
        submenu: [],
        hidden: !permissions.includes('view.user')
      }
    ],
  }];





  return [
    ...dashBoardNavTree,
    ...customersNavTree,
    ...settingsNavTree,
    ...systemNavTree,
    ...businessNavTree,
    ...insuranceNavTree,
    ...financialReportNavTree,
    ...reportNavTree,
    ...invoicingNavTree,
    ...mandateNavTree,
    ...promoNavTree
  ]
};

export default navigationConfig;
