import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE } from 'constants/ThemeConstant';
export const APP_NAME = 'P2Vest Rotura';
export const API_BASE_URL = 'https://rotura-api.p2vest.com/v1/';
//export const API_BASE_URL = 'http://localhost:1502/v1/';
export const APP_PREFIX_PATH = '';
export const AUTH_PREFIX_PATH = '';
export const THEME_CONFIG = {
  navCollapsed: true,
  sideNavTheme: SIDE_NAV_LIGHT,
  locale: 'en',
  navType: NAV_TYPE_SIDE,
  topNavColor: '#3e82f7',
  headerNavColor: '#ffffff',
  mobileNav: false,
};
