import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig'

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content"/>}>
      <Switch>
        <Route path={'/dashboard'} component={lazy(() => import(`./dashboards`))} />
        <Route path={'/dashboard'} component={lazy(() => import(`./dashboards`))} />
        <Route path={'/users'} component={lazy(() => import(`./users`))} />
        <Route path={'/pendingdocuments'} component={lazy(() => import(`./pendingdocuments`))} />
        <Route path={'/unmatchedimages'} component={lazy(() => import(`./unmatchedimages`))} />
        <Route path={'/statements'} component={lazy(() => import(`./statements`))} />
        <Route path={'/profile'} component={lazy(() => import(`./profile`))} />
        <Route path={'/reports'} component={lazy(() => import(`./reports`))} />
        <Route path={'/manual_loan'} component={lazy(() => import(`./manual_loan`))} />
        <Route path={'/error'} component={lazy(() => import(`./errorlogs`))} />
        <Route path={'/business'} component={lazy(() => import(`./business`))} />    
        <Route path={'/schools'} component={lazy(() => import(`./schools`))} />  
        <Route path={'/p2p_loans'} component={lazy(() => import(`./p2p_loans`))} />
        <Route path={'/mandates'} component={lazy(() => import(`./mandate`))} />
        <Route path={'/posting'} component={lazy(() => import(`./loanposting`))} />
        <Route path={'/loan_requests'} component={lazy(() => import(`./loan_requests`))} />
        <Route path={'/guarantors'} component={lazy(() => import(`./guarantors`))} />
        <Route path={'/notifications'} component={lazy(() => import(`./notifications`))} />
        <Route path={'/lenders'} component={lazy(() => import(`./lenders`))} />
        <Route path={'/transactions'} component={lazy(() => import(`./transactions`))} />
        <Route path={'/groups'} component={lazy(() => import(`./groups`))} />
        <Route path={'/whitelist'} component={lazy(() => import(`./apps/e-commerce/orders`))} />
        <Route path={'/profile'} component={lazy(() => import(`./apps/e-commerce/orders`))} />
        <Route path={'/offers'} component={lazy(() => import(`./p2p_loans/offers`))} />

        <Route path={'/insurance'} component={lazy(() => import(`./insurance`))} />
        <Route path={'/merchants'} component={lazy(() => import(`./merchants`))} />


        <Route path={'/promos/promo'} component={lazy(() => import(`./promos`))} />

        <Route path={'/invoicings'} component={lazy(() => import(`./invoicings`))} />
        <Route path={'/linecredit'} component={lazy(() => import(`./linecredit`))} />

        <Route path={'/internal'} component={lazy(() => import(`./internal`))} />
        <Route path={'/tiers'} component={lazy(() => import(`./tiers`))} />
        <Route path={'/system/users'} component={lazy(() => import(`./system/users`))} />
        <Route path={'/system/roles'} component={lazy(() => import(`./system/roles`))} />
        <Route path={'/risk'} component={lazy(() => import(`./apps/e-commerce/orders`))} />
        <Route path={'/settings'} component={lazy(() => import(`./apps/e-commerce/orders`))} />
        <Route path={'/scoring'} component={lazy(() => import(`./scoring`))} />
        <Route path={'/ics'} component={lazy(() => import(`./ics`))} />
        <Route path={'/verification'} component={lazy(() => import(`./verification`))} />

        <Route path={''} component={lazy(() => import(`./components/feedback/result/Result404`))}/>
      </Switch>
    </Suspense>
  )
}

export default React.memo(AppViews);
